import React, { useState } from 'react';
import { Box } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import IconButton from '@mui/material/IconButton';
import { useTranslation } from 'react-i18next';

const LANGS = [
  {
    value: 'tr',
    label: 'Türkçe',
    icon: 'https://icons.iconarchive.com/icons/wikipedia/flags/256/TR-Turkey-Flag-icon.png',
  },
  {
    value: 'en',
    label: 'English',
    icon: 'https://cdn-icons-png.flaticon.com/512/330/330425.png',
  },
];

export default function LanguagePopover() {
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(null);
  const [selectedLang, setSelectedLang] = useState(i18n.language);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleChangeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    setSelectedLang(lang);
    handleClose();
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          width: 40,
          height: 40,
          ...(open && {
            bgcolor: 'action.selected',
          }),
        }}
      >
        <img src={LANGS.find((lang) => lang.value === selectedLang).icon} alt={LANGS.find((lang) => lang.value === selectedLang).label} />
      </IconButton>

      <Popover
        open={!!open}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1,
            ml: 0.75,
            width: 180,
          },
        }}
      >
        {LANGS.map((option) => (
          <MenuItem
            key={option.value}
            selected={option.value === selectedLang}
            onClick={() => handleChangeLanguage(option.value)}
            sx={{ typography: 'body2', py: 1 }}
          >
            <Box component="img" alt={option.label} src={option.icon} sx={{ width: 28, mr: 2 }} />
            {option.label}
          </MenuItem>
        ))}
      </Popover>
    </>
  );
}
