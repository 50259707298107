import app from "../api/app";

const StoreUserServices = {
    getAll:() => app.get('store-user'),
    create:(data) => app.post('store-user/create',data),
    createUser:(data) => app.post('store-user/create-user',data),
    delete:(id) => app.delete(`store-user/${id}`),
    login:(data) => app.post(`store-user/login`,data),
    loginStore:(data) => app.post(`store-user/loginStore`,data),
    me:(_token) => app.get('store-user/me'),
    updateById:(id,data) => app.put(`store-user/${id}`,data),
    getById:(id)  => app.get(`store-user/${id}`)
}

export default StoreUserServices

