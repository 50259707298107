import React, {useEffect, useState} from 'react';
import {Navigate, useNavigate} from 'react-router-dom';
import storeUserServices from "../services/storeUserServices";

// eslint-disable-next-line react/prop-types
const GuestMiddleware = ({children}) => {
    const [isAuthenticated, setIsAuthenticated] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const checkAuth = async () => {
            try {
                const token = localStorage.getItem('_authToken');
                if (!token) {
                    setIsAuthenticated(false);
                    localStorage.removeItem('_authToken');
                    return;
                }

                storeUserServices.me(token).then((res) => {
                    setIsAuthenticated(res)
                }).catch(() => {
                    setIsAuthenticated(false)
                })

            } catch (error) {
                console.error('Auth check failed', error);
                setIsAuthenticated(false);
            }
        };

        checkAuth();
    }, []);

    useEffect(() => {
        if (isAuthenticated === true) {
            navigate('/', { replace: true });
        }
    }, [isAuthenticated, navigate]);

    return isAuthenticated ?  navigate('/', { replace: true }) : children;
};

export default GuestMiddleware;
