import SvgColor from 'src/components/svg-color';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

const icon = (name) => (
    <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{width: 1, height: 1}}/>
);


const NavConfigComponent = () => {
    const { t } = useTranslation();

const navConfig = [
    {
        title: t('Raporlar'),
        path: '/',
        icon: icon('ic_analytics'),
    },
    // {
    //   title: 'Hizmet Noktaları',
    //   path: '/servicePoints',
    //   icon: icon('ic_user'),
    // },
    {
        title: t('Ürünler'),
        path: '/products',
        icon: icon('ic_product_attributes'),
    },
    {
        title: 'Sevkiyat Yönetimi',
        path: '/shipment-management',
        icon: icon('ic_shipment_management'),
    },
    {
        title: 'Kullanıcılar',
        path: '/users',
        icon: icon('ic_store_users'),
        children: [
            {
              title: "",
              path: '/users/create',
            },
          ],
    },
    {
      title: 'Siparişlerim',
      path: '/orders',
      icon: icon('ic_orders'),
    },
    {
        title: 'Ödeme Bilgileri',
        path: '/payment-information',
        icon: icon('ic_user'),
    },
    {
        title: 'Belgelerim',
        path: '/storeDocs',
        icon: icon('ic_docs'),
    },
    {
        title: 'Canlı Kamera İzle',
        path: '/storeStream',
        icon: icon('ic_camera'),
    },
];

    return navConfig;
};

export default NavConfigComponent;
