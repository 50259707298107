import {createSlice} from '@reduxjs/toolkit';

export const ordersSlice = createSlice({
    name: 'orders',
    initialState: {
        data: [],
    },
    reducers: {
        fetchOrders(state,action){
            state.data = action.payload;
        }
    },
});
export const {fetchOrders} = ordersSlice.actions;