import {configureStore} from '@reduxjs/toolkit'

import {ordersSlice} from './features/orders';
import {productsSlice} from "./features/products";
import {storeUserRolesSlice} from "./features/storeUserRoles";
import {storeUsersPermissionsSlice} from "./features/storeUsersPermissions";
import {usersSlice} from "./features/users";
import {rolePermissionsSlice} from "./features/rolePermissions";
import {appSettingsSlice} from "./features/appSettings";
import {categoriesSlice} from "./features/categories";
import {categoryAttributesSlice} from "./features/categoryAttributes";
import {attributesSlice} from "./features/attribute";
import {attributeValuesSlice} from "./features/attributeValues";
import {variantsSlice} from "./features/variants";
import {productCategoriesSlice} from "./features/productCategories";
import {meSlice} from "./features/me";

export const store = configureStore({
    reducer: {
        products:productsSlice.reducer,
        storeUserRoles:storeUserRolesSlice.reducer,
        storeUsersPermissions:storeUsersPermissionsSlice.reducer,
        orders:ordersSlice.reducer,
        categoryAttributes:categoryAttributesSlice.reducer,
        appSettings:appSettingsSlice.reducer,
        categories:categoriesSlice.reducer,
        users:usersSlice.reducer,
        attributeValues:attributeValuesSlice.reducer,
        variants: variantsSlice.reducer,
        rolePermissions: rolePermissionsSlice.reducer,
        attributes:attributesSlice.reducer,
        productCategories: productCategoriesSlice.reducer,
        me:meSlice.reducer,
    },
})