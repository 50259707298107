import app from "../api/app";

const AppSettingsServices = {
    getAll:() => app.get('app-setting'),
    create:(data) => app.post('app-setting/create',data),
    delete:(id) => app.delete(`app-setting/${id}`),
    updateById:(id,data) => app.put(`app-setting/update/${id}`,data)
}

export default AppSettingsServices

