/* eslint-disable perfectionist/sort-imports */
import 'src/global.css';

import {useScrollToTop} from 'src/hooks/use-scroll-to-top';

import Router from 'src/routes/sections';
import ThemeProvider from 'src/theme';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useEffect, useState} from "react";
import 'react-phone-input-2/lib/style.css'
import storeUserServices from "./services/storeUserServices";
import RoleCreate from "./utils/roleCreate";
// ----------------------------------------------------------------------

export default function App() {
    useScrollToTop();

    return (
        <ThemeProvider>
            <Router/>
            <ToastContainer/>
        </ThemeProvider>
    );
}
