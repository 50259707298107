import axios from 'axios';

import { API_URL } from "../config/config";

const app = axios.create({
    baseURL: API_URL,
});

app.interceptors.request.use(config => {
    const token = localStorage.getItem('_authToken');
    
    config.headers['accept-language'] = 'tr';
    config.headers.Authorization = token

    return config;
});

export default app;