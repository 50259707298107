import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box, Modal } from '@mui/material';
import storeUserServices from '../services/storeUserServices';
import CommonInformation from '../layouts/dashboard/commonInformation';
import storeUserDetailServices from '../services/storeUserDetailServices';
import { fetchMe } from '../redux/features/me';

// eslint-disable-next-line react/prop-types
const AuthMiddleware = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const navigate = useNavigate();
  const [active, setActive] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [open, setOpen] = useState(false);
  const [storeId, setStoreId] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    const checkAuth = async () => {
      try {
        const token = localStorage.getItem('_authToken');
        if (!token) {
          setIsAuthenticated(false);
          return;
        }

        const res = await storeUserServices.me(token);
        await dispatch(fetchMe(res.data.data));
        setIsAuthenticated(res);
        setStoreId(res?.data?.data?.storeUserStores[0]?.store_id);

        const resStoreUserDetail = await storeUserDetailServices.id(
          res?.data?.data?.storeUserStores[0]?.store_id
        );

        setActive(resStoreUserDetail.data.data.active);
      } catch (error) {
        console.error('Auth check failed', error);
        setIsAuthenticated(false);
      }
    };

    checkAuth();
  }, []);

  useEffect(() => {
    if (isAuthenticated === false) {
      navigate('/login', { replace: true });
      localStorage.removeItem('_authToken');
    }
  }, [isAuthenticated, navigate]);

  useEffect(() => {
    if (active) {
      setIsModalOpen(true);
    }
  }, [active]);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const handleBackdropClick = (event) => {
    event.stopPropagation();
  };

  return (
    <>
      {isModalOpen}
      {isAuthenticated && children}
      {/* {active && <Modal
                open={isModalOpen}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                BackdropProps={{
                    onClick: handleBackdropClick, // Backdrop tıklamasını durdur
                }}
            >
                <Box sx={style}>
                    <CommonInformation storeId={storeId} onClose={handleCloseModal}/>
                </Box>
            </Modal>} */}
    </>
  );
};

export default AuthMiddleware;
